<div class="d-flex flex-column">
    <h2 class="text-uppercase mt-0 mb-0 SpotEveRes mt-md-4">Resources</h2>
    <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
        <li class="nav-item w-50 text-center">
            <a target="_blank" class="nav-link active text-center BTNtab" id="home-tab" data-toggle="tab" href="#resource-tab1" role="tab" aria-controls="home" aria-selected="true">MOST READ</a>
        </li>
        <li class="nav-item w-50">
            <!--<a target="_blank" class="nav-link pl-3 pr-1 text-center" id="profile-tab" data-toggle="tab" href="/resources" role="tab" aria-controls="profile" aria-selected="false">ALL RESOURCES</a>-->
            <a class="nav-link pl-3 BTNtab text-center" id="profile-tab"  data-toggle="tab" aria-controls="navbarTogglerDemo03" routerLink="/resources">ALL RESOURCES</a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-1 resource-tab1-slider1" id="resource-tab1" role="tabpanel" aria-labelledby="home-tab">
            <!-- mobile view slider article -->
            <div class="col-12 d-block d-md-none pt-3 p-0">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomeResources" *ngIf="loaded">
                    <div ngxSlickItem *ngFor="let resData of resDatas" class="slide">
                      <a [href]="resData.RouteURL" target="_blank">
                        <div class="text-center">
                            <img class="resources-img-h pb-2" [src]='resData.ImageURL' alt="resource image">
                        </div>
                        </a>
                        <!-- <p class="para-2 mb-0 CatTag" *ngIf='resData.Tag!="" && resData.Tag!=" " && resData.Tag!=null; else BlankTag'>{{resData.Tag}}</p>
                        <ng-template #BlankTag>
                         <p class="para-2 mb-0 tag"></p>
                        </ng-template> -->
                        <p class="para-2 mb-0 CatTag" *ngIf='resData.Tag!=" " && resData.Tag!="" && resData.Tag!=null; else BlankTag'>{{resData.Tag}}</p>
                          <ng-template #BlankTag>
                            <p class="para-2 mb-0 tag"></p>
                          </ng-template>
                        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
                        <a [href]="resData.RouteURL" target="_blank">
                        <h4 class="text-uppercase mResoDiv homeRTitle">{{resData.WhitePaperTitle}}</h4>
                        </a>
                        <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>
                    </div>
                </ngx-slick-carousel>
                <!-- <div class="mt-2 mb-4">
                    <app-square-ad></app-square-ad>
                </div> -->
            </div>
            <!-- mobile view slider end -->


            <div class="row d-none d-md-flex resoMainDiv" *ngIf="loaded">
                <div class="col-md-12 pt-1" *ngFor="let resData of resDatas; let  i= index">
                    <div>

                           <a [href]="resData.RouteURL" target="_blank">
                            <!-- <a  routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                            <img class="resources-img-h pb-2 pr-5" [src]='resData.ImageURL' alt="resource image">
                           </a>
                           <p class="para-2 mb-0 CatTag" *ngIf="resData.Tag!=' '; else BlankTag">{{resData.Tag}}</p>
                           <ng-template #BlankTag>
                            <p class="para-2 mb-0 tag"></p>
                           </ng-template>
                            <a [href]="resData.RouteURL" target="_blank">
                            <h4 class="text-uppercase mResoDiv homeRTitle">{{resData.WhitePaperTitle}}</h4>
                            </a>
                            <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>
                            <!-- <div *ngIf="i==2 || i==5" class="pt-1 pb-3">
                                <app-square-ad></app-square-ad>
                            </div> -->
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="text-center ADdiv" style="display:flex; justify-content:center; align-items:center">
        <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="HomeADAfterWhitepaper"></iframe></div>
</div>
<div class="d-flex flex-column">    

    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-2 resource-tab1-slider2" id="resource-tab1" role="tabpanel" aria-labelledby="home-tab">
            <!-- mobile view slider article -->
            <div class="col-12 d-block d-md-none pt-3 p-0">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigHomeResources" *ngIf="loaded">
                    <div ngxSlickItem *ngFor="let resData of resDatas" class="slide">
                        <a [href]="resData.RouteURL" target="_blank">
                        <div class="text-center">
                            <img class="resources-img-h pb-2" [src]='resData.ImageURL' alt="resource image">
                        </div>
                        </a>
                        <!-- <p class="para-2 mb-0 text-uppercase">CORE re</p> -->
                        <!-- <p class="para-2 mb-0 CatTag" *ngIf='resData.Tag!="" && resData.Tag!=" " && resData.Tag!=null; else BlankTag'>{{resData.Tag}}</p>
                        <ng-template #BlankTag>
                            <p class="para-2 mb-0 tag"></p>
                        </ng-template> -->
                        <p class="para-2 mb-0 CatTag" *ngIf='resData.Tag!=" " && resData.Tag!="" && resData.Tag!=null; else BlankTag'>{{resData.Tag}}</p>
                          <ng-template #BlankTag>
                            <p class="para-2 mb-0 tag"></p>
                          </ng-template>
                        <a [href]="resData.RouteURL" target="_blank">
                        <h4 class="text-uppercase mResoDiv homeRTitle">{{resData.WhitePaperTitle}}</h4>
                        </a>
                        <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>

                    </div>
                </ngx-slick-carousel>
                <!-- <div class="mt-2 mb-4">
                    <app-square-ad></app-square-ad>
                </div> -->
            </div>
            <!-- mobile view slider end -->


            <div class="row d-none d-md-flex resoMainDiv" *ngIf="loaded">
                <div class="col-md-12 pt-1" *ngFor="let resData of resDatas; let  i= index">
                    <!-- <div class="cursor-p" routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                          <a [href]="resData.RouteURL" target="_blank">
                            <!-- <a  routerLink='/resources/{{resData.ResourceType +"s" | lowercase }}/{{resData.RouteURL}}/{{resData.ID}}'> -->
                            <img class="resources-img-h pb-2 pr-5" [src]='resData.ImageURL' alt="resource image">
                           </a>
                            <p class="para-2 mb-0 CatTag" *ngIf="resData.Tag!=' '; else BlankTag">{{resData.Tag}}</p>
                            <ng-template #BlankTag>
                           <p class="para-2 mb-0 tag"></p>
                           </ng-template>
                           <a [href]="resData.RouteURL" target="_blank">
                            <h4 class="text-uppercase mResoDiv">{{resData.WhitePaperTitle}}</h4>
                           </a>
                            <p class="para-3 text-capitalize">{{resData.ResourceType}}</p>

                            <!-- <div *ngIf="i==2 || i==5" class="pt-1 pb-3">
                                <app-square-ad></app-square-ad>
                            </div> -->
                        
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="text-center ADdiv" style="display:flex; justify-content:center; align-items:center">
        <iframe scrolling="no" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="HomeADAfterArticle"></iframe>
    </div>


    <!-- <button class="text-uppercase mt-auto thm-btn btn-theme-success w-100 pt-3 pb-3" routerLink="resources">more resources</button> -->
</div>